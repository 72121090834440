import {Component, OnDestroy, OnInit} from '@angular/core';
import {AnalyticsEvent, NavigationService} from '../../../core';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {AnalyticsService} from "../../../core/services";
import {UserInfoQuery, UserInfoService} from "../../../core/store";
import {takeUntil} from "rxjs/operators";
import {Currency} from "../../constants";

@Component({
  selector: 'app-coin-store',
  templateUrl: './coin-store.component.html',
  styleUrls: ['./coin-store.component.scss'],
})
export class CoinStoreComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<boolean>();
  private currency: Currency = Currency.SWPC;

  constructor(
    private router: Router,
    private readonly analyticsService: AnalyticsService,
    private readonly navigationService: NavigationService,
    private readonly userInfoQuery: UserInfoQuery,
    private readonly userInfoService: UserInfoService,
    private readonly dialogRef: MatDialogRef<CoinStoreComponent>
  ) {}

  ngOnInit(): void {

      const {scTotal, lcTotal} = this.userInfoService.getBalanceTotals()

      this.userInfoQuery.selectedCurrency$.pipe(
          takeUntil(this.destroy$)
      ).subscribe((currency) => {
        this.currency = currency
      })

      this.analyticsService.trackEvent(AnalyticsEvent.COIN_STORE_OPENED, {
        currency: this.currency === Currency.LUKC ? 'lc' : 'sc',
        sc_balance: scTotal,
        lc_balance: lcTotal
      })

  }

  close(): void {
    this.dialogRef.close();
    if (this.router.url.startsWith('/coinstore')) {
      this.navigationService.navigateTo('/games');
    }
  }

  navigateTo(path: string): void {
    this.close();
    this.navigationService.navigateTo(path);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}