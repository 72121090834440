import {Component, EventEmitter, OnDestroy, OnInit, Output, Renderer2} from '@angular/core';
import {NotificationService, ScriptService} from "../../../core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {environment} from "../../../../environments/environment";
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { FormEventTrackingService } from 'src/app/core/services/form-event-tracking.service';

declare var Spreedly: any;

@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<boolean>();
    private formId = 'paymentForm';

    @Output() onClose: EventEmitter<boolean> = new EventEmitter();
    @Output() onPayment: EventEmitter<string> = new EventEmitter();

    public form!: UntypedFormGroup;
    public isSpreedlyReady: boolean = false;
    public isNumberValid: boolean = false;
    public isCvvValid: boolean = false;

    constructor(
        private renderer: Renderer2,
        private readonly scriptService: ScriptService,
        private readonly _fb: UntypedFormBuilder,
        private readonly notificationService: NotificationService,
        private readonly formEventTrackingService: FormEventTrackingService,
    ) {
    }

    ngOnInit(): void {
        this.buildForm();
        this.loadSpreedly()
    }

    public close(): void {
        this.onClose.emit(true);
    }

    private buildForm(): void {
        this.form = this._fb.group(
        {
            firstName: [null, [
                Validators.required
            ]],
            lastName: [null, [
                Validators.required
            ]],
            month: [null, [
                Validators.required,
                Validators.pattern('^\\d{2}$')
            ]],
            year: [null, [
                Validators.required,
                Validators.pattern('^\\d{4}$')
            ]]
        });

        this.formEventTrackingService.initializeFormStatus(this.formId, this.form);
        this.form.valueChanges.pipe(
            debounceTime(500),
            takeUntil(this.destroy$),
        )
        .subscribe(() => this.formEventTrackingService.trackFieldEvents(this.formId, this.form));
    }

    public onSubmit() {
        const requiredFields: any = {
            full_name: `${this.form.get('firstName')?.value} ${this.form.get('lastName')?.value}`,
            month: this.form.get('month')?.value,
            year: this.form.get('year')?.value
        };

        Spreedly.tokenizeCreditCard(requiredFields);
    }

    private loadSpreedly() {
        const {SCRIPT_URL, TOKEN} = environment.SPREEDLY_SETTINGS!;

        this.scriptService.loadJsScript(this.renderer, SCRIPT_URL, "spreedlyScript", () => {
            Spreedly.init(TOKEN, {
                "numberEl": "spreedly-number",
                "cvvEl": "spreedly-cvv"
            });

            Spreedly.on("ready", () => {
                this.isSpreedlyReady = true;

                Spreedly.setStyle("number", "width: 100%; min-height: 50px; padding: 0 15px; color: #E7F3F3; font-size: 100%;");
                Spreedly.setStyle("cvv", "width: 100%; min-height: 50px; padding: 0 15px; color: #E7F3F3; font-size: 100%;");
            });

            Spreedly.on('errors', (errors: any) => {
                for (let i = 0; i < errors.length; i++) {
                    this.notificationService.showNotification({
                        type: 'error',
                        message: errors[i].message
                    });
                }
            });

            Spreedly.on('paymentMethod', (token: any, pmData: any) => {
                this.onPayment.emit(token);
            });

            Spreedly.on('fieldEvent', (name: any, type: any, activeEl: any, inputProperties: any) => {
                if (name == "number") {
                    const numberParent = document.getElementById("spreedly-number");
                    const numberForm = numberParent?.closest(".form-control__block")

                    if (numberForm && type == "input") {
                        this.isNumberValid = inputProperties["validNumber"];

                        numberForm.classList.remove("invalid");
                        numberForm.classList.remove("valid");

                        numberForm.classList.add(this.isNumberValid ? "valid" : "invalid");
                        Spreedly.setStyle("number", this.isNumberValid ? "color: #00FF66;" : "color: #FB3838;");
                    }
                }

                if (name == "cvv") {
                    const cvvParent = document.getElementById("spreedly-cvv");
                    const cvvForm = cvvParent?.closest(".form-control__block")

                    if (cvvForm && type == "input") {
                        this.isCvvValid = inputProperties["validCvv"];

                        cvvForm.classList.remove("invalid");
                        cvvForm.classList.remove("valid");

                        cvvForm.classList.add(this.isNumberValid ? "valid" : "invalid");
                        Spreedly.setStyle("cvv", this.isNumberValid ? "color: #00FF66;" : "color: #FB3838;");
                    }
                }
            });
        });

    }

    public ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
