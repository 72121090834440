import { Injectable } from '@angular/core';
import {
  ActivatePassword,
  FacebookUser,
  GenerateCodeRequest,
  SignInModel,
  SignUpModel,
  VerifyCodeRequest,
} from 'src/app/core/interfaces';
import { AuthProvider } from 'src/app/core/providers';
import {
  IngeniousService,
  NavigationService,
  NotificationService,
} from 'src/app/core/services';
import { InvalidParam } from 'src/app/core/abstractions/interfaces/problem-detail';
import {
  AccountService,
  AuthService,
  GamesService,
  HubService,
  ResponsibleGamblingService,
  UserInfoService,
} from '../store';
import { Observable } from 'rxjs';
import { ErrorMessages } from '../errors/errors';
import jwt_decode from 'jwt-decode';
import {AnalyticsEvent} from '../enums';
import { EVENT_TYPES } from '../../shared';
import { EventBus } from '../infrastructure';
import {AnalyticsService} from "../services/analytics.service";

const EMAIL_ADDRESS_RESPONSE = 'Email address response error';

@Injectable({ providedIn: 'root' })
export class AuthFacade {
  public errorMessage: string | null = null;
  public listOfErrors: string = '';

  constructor(
    private readonly eventBus: EventBus,
    private readonly _authProvider: AuthProvider,
    private readonly _notification: NotificationService,
    private readonly _nav: NavigationService,
    private readonly hubService: HubService,
    private readonly gamesService: GamesService,
    private readonly userInfoService: UserInfoService,
    private readonly rgService: ResponsibleGamblingService,
    private readonly accountService: AccountService,
    private readonly ing: IngeniousService,
    private readonly authService: AuthService,
    private readonly analyticsService: AnalyticsService
  ) {}

  public login(
    model: SignInModel | FacebookUser,
    isFacebook: boolean = false,
    isFacebookSignUp: boolean = false
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.authService.dispose();
      this._authProvider
        .exchange(
          isFacebook ? (model as FacebookUser) : (model as SignInModel),
          isFacebook,
          isFacebookSignUp
        )
        .subscribe(
          async token => {
            this.authService.setContext(token);

            if (token.access_token) {
              let decoded: any = jwt_decode(token.access_token);
              this.ing.userId = decoded.sub;
              this.analyticsService.trackEvent(AnalyticsEvent.LOGIN_COMPLETED, {
                player_id: Number(decoded.sub)
              })
            }
            this.ing.ingeniousHandler.next('login');
            this.eventBus.Publish<void>(EVENT_TYPES.PLAYER_SIGN_IN);

            resolve(true);
          },
          error => {
            this.errorMessage = null;
            this.listOfErrors = '';
            let er = error.error.invalidParams;
            if (er?.length > 0) {
              er.forEach((value: InvalidParam) => {
                switch (value.reason) {
                  case 'Failed Attempts':
                    this.errorMessage = ErrorMessages.ManyAttempts;
                    this.listOfErrors += this.errorMessage + '\n';
                    break;

                  case 'Daily-Time-Limit':
                    this.errorMessage = ErrorMessages.DailyTimeLimit;
                    this.listOfErrors += this.errorMessage + '\n';
                    break;

                  case 'SelfExcluded':
                    this.errorMessage = ErrorMessages.SelfExcluded;
                    this.listOfErrors += this.errorMessage + '\n';
                    break;

                  default:
                    this.errorMessage = value.reason;
                    this.listOfErrors += this.errorMessage + '\n';
                }
              });
              if (this.listOfErrors.includes(ErrorMessages.SelfExcluded)) {
                this.closeAccount(this.listOfErrors);
              } else {
                this._notification.showNotification({
                  type: 'error',
                  message: this.listOfErrors,
                });
              }
            } else {
              this._notification.showNotification({
                type: 'error',
                message: this.setErrorMessage(error),
              });
            }
            this.analyticsService.trackEvent(AnalyticsEvent.LOGIN_ATTEMPT_FAILED, {
                error_type: error.error.title,
                error_message: this.setErrorMessage(error)
            });
            reject(false);
          }
        );
    });
  }

  public closeAccount(limitEnd: string = ''): void {
    if (limitEnd.length > 0) {
      let strings = limitEnd.split('\n');
      sessionStorage.setItem(
        'RestrictionEndDate',
        new Date(strings[1]).toLocaleString()
      );
    } else {
      this.logout();
    }

    this._nav.navigateAndRefreshTo('closed');
  }

  public register(model: SignUpModel): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this._authProvider.register(model).subscribe({
        next: async res => {
            this.analyticsService.trackEvent(AnalyticsEvent.SIGN_UP_COMPLETED, {
                player_id: res
            });

          resolve(true);
        },
        error: error => {
          this._notification.showNotification({
            type: 'error',
            message: error.error.detail,
          });

          resolve(false);
        },
      });
    });
  }

  public generateCode(data: GenerateCodeRequest): Observable<void> {
    return this._authProvider.generateCode(data);
  }

  public verifyCode(data: VerifyCodeRequest): Observable<void> {
    return this._authProvider.verifyCode(data);
  }

  public activatePassword(data: ActivatePassword): Observable<void> {
    return this._authProvider.activatePassword(data);
  }

  public logout(): void {
    this._authProvider.logout();
    this.authService.dispose();
    this.accountService.clear();
    this.userInfoService.clear();
    this.rgService.clear();
    this.gamesService.clear();
    this.hubService.disconnect();
  }

  private setErrorMessage(error: any): string {
    switch (true) {
      case !!error.error.detail: {
        return error.error.detail;
      }
      case typeof error.error.error === 'string' &&
        error.error.error === EMAIL_ADDRESS_RESPONSE: {
        return ErrorMessages.FacebookSignUp;
      }
      default: {
        return error.error.error;
      }
    }
  }
}