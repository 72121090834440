<div class="package-container" *ngIf="!isLoading; else placeholder">
    <div class="package-buttons__title">
        Buy a Lucky Coins package!
    </div>
    <div *ngIf="coinPackages.length; else empty">
        <div class="package-buttons">
            <div class="package-coin" *ngFor="let coin of coinPackages; index as i">
                <app-coin-card [coin]="coin" [hideIcon]="true" [coinPositionIndex]="i" ></app-coin-card>
            </div>
        </div>
    </div>
    <ng-template #empty>
        <div class="no-results">
            <i class="u_annoyed" aria-describedby="No result"></i>
            <span>There is no coin package available for you at the moment</span>
        </div>
    </ng-template>
</div>
<ng-template #placeholder>
    <div class="package-container">
        <div class="package-buttons__title skeleton-loading size-30"></div>
        <div class="package-buttons">
            <div *ngFor="let item of [].constructor(6)" class="package-coin skeleton-loading size-192"></div>
        </div>
    </div>
</ng-template>