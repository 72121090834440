export enum AnalyticsEvent {
    SIGN_UP_FORM = 'sign_up_form',
    SIGN_UP_COMPLETED = 'sign_up_completed',
    KYC_STARTED = 'kyc_started',
    KYC_FINISHED = 'kyc_finished',
    COIN_STORE_OPENED = 'coin_store_opened',
    CHECKOUT_STARTED = 'checkout_started',
    COIN_PACKAGE_VIEWED = 'coin_package_viewed',
    COIN_PACKAGE_SELECTED = 'coin_package_selected',
    PURCHASE_COMPLETED = 'purchase_completed',
    FORM_ERROR = 'form_error',
    FIELD_STARTED = 'field_started',
    FIELD_COMPLETED = 'field_completed',
    PROFILE_FORM = 'profile_form',
    PROFILE_COMPLETED = 'profile_completed',
    CURRENCY_CHANGED = 'currency_changed',
    LOGIN_FORM = 'login_form',
    LOGIN_COMPLETED = 'login_completed',
    LOGIN_ATTEMPT_FAILED = 'login_attempt_failed',
}