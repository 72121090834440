import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {FacebookLoginProvider, SocialAuthService, SocialUser} from "@abacritt/angularx-social-login";
import {Subscription} from "rxjs";
import {
    AccountProvider, AnalyticsEvent, AnalyticsService,
    AuthFacade,
    FacebookUser,
    NavigationService,
    NotificationService,
    SignInModel
} from "../../../core";
import { RegexPatterns } from "../../constants";
import {debounceTime, distinctUntilChanged, filter, switchMap, takeUntil} from "rxjs/operators";
import { FormEventTrackingService } from 'src/app/core/services/form-event-tracking.service';

@Component({
    selector: 'app-sign-in-form',
    templateUrl: './sign-in-form.component.html',
    styleUrls: ['./sign-in-form.component.scss'],
    providers: [SocialAuthService]
})
export class SignInFormComponent implements OnInit, OnDestroy {
    private formId = 'signInForm';

    public isPasswordVisible: boolean = false;
    public form!: UntypedFormGroup;

    public user!: SocialUser;
    public isLoading: boolean = false;
    public isSignedIn: boolean = false;
    public subscriptions: Subscription[] = [];

    @Output() onRedirect: EventEmitter<boolean> = new EventEmitter();

    constructor(
        private readonly _fb: UntypedFormBuilder,
        private readonly _authFacade: AuthFacade,
        private readonly _nav: NavigationService,
        private readonly _auth: SocialAuthService,
        private readonly _account: AccountProvider,
        private readonly _notification: NotificationService,
        private readonly formEventTrackingService: FormEventTrackingService,
        private readonly analyticsService: AnalyticsService,
    ) {
        this._auth.authState.subscribe((user: FacebookUser) => {
            this.user = user;
            this.isSignedIn = (user !== null && !!user.id);
            if (this.isSignedIn) {
                this._authFacade.login(user, true).then(() => {
                    this.performSuccessRedirect()
                }, error => {
                    this._auth.signOut();
                    throw error;
                });
            }
        })
    }

    public ngOnInit(): void {
        this.buildForm();
        this.analyticsService.trackEvent(AnalyticsEvent.LOGIN_FORM)
    }

    public async signIn(): Promise<void> {
        this.isLoading = true;

        const model: SignInModel = {
            email: this.form.get('email')!.value,
            password: this.form.get('password')!.value
        }

        try {
            await this._authFacade.login(model).then(() => {
                this.performSuccessRedirect();
            });
        } catch(error) {
            throw error;
        } finally {
            this.isLoading = false;
        }
    }

    public signInByFacebook(): void {
        this._auth.signIn(FacebookLoginProvider.PROVIDER_ID).catch(error => {
            this._notification.showNotification({
                type: 'error',
                message: this.getFacebookCustomErrorMessage(error.error)
            });
        });
    }

    private getFacebookCustomErrorMessage(error: any): string {
        if (error === 'You don’t have an account')
            return 'You don’t have an account, please sign up first.';
        return error
    }

    public resetPassword(): void {
        this._nav.navigateTo('/auth/reset');
        this.onRedirect.emit()
    }

    public signUp(): void {
        this.onRedirect.emit();
        this._nav.navigateTo('/auth/sign-up');
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private buildForm(): void {
        this.form = this._fb.group(
        {
            password: [null, [
                Validators.required,
                Validators.minLength(8)
            ]],
            email: [null, [
                Validators.required,
                Validators.pattern(RegexPatterns.Email)
            ]]
        });

        this.formEventTrackingService.initializeFormStatus(this.formId, this.form);
        this.form.valueChanges.pipe(
            debounceTime(500),
            takeUntil(this.subscriptions),
        )
        .subscribe(() => this.formEventTrackingService.trackFieldEvents(this.formId, this.form));
        
        this.emailValueHandler();
    }

    private emailValueHandler(): void {
        this.subscriptions.push(
            this.form.get('email')?.valueChanges
                .pipe(
                    debounceTime(400),
                    distinctUntilChanged(),
                    filter(() => !!(this.form.get('email')?.valid)),
                    switchMap((email: string) => this._account.checkEmail(email))
                )
                .subscribe(event => {
                }, error => {
                    if (error.error.status === 400) {
                        this._notification.showNotification({
                            type: 'error',
                            message: error.error.detail,
                            action: () => {
                                this.emailValueHandler()
                            }
                        });
                    }
                })!
        );
    }

    private performSuccessRedirect() {
        const urlAfter = localStorage.getItem('urlAfterSignIn');

        if (urlAfter) {
            localStorage.removeItem('urlAfterSignIn')
            this._nav.navigateAndRefreshTo(urlAfter);
        } else {
            this._nav.navigateAndRefreshTo('');
        }
    }
}