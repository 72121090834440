import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services';
import {
  AccountDetails, AnalyticsEvent, AnalyticsService,
  AuthFacade,
  GtmEvent,
  GtmService,
  NavigationService,
  NotificationService,
} from '../../../core';
import {AccountService, AuthService, UserInfoService} from '../../../core/store';
import { CoinStoreComponent } from '../coin-store';

@Component({
  selector: 'app-edit-details-modal',
  templateUrl: './edit-details-modal.component.html',
  styleUrls: ['./edit-details-modal.component.scss'],
})
export class EditDetailsModalComponent implements OnInit {
  public userDetails: AccountDetails | null = null;
  public isLoading: boolean = false;

  constructor(
    private readonly modalService: ModalService,
    private readonly accountService: AccountService,
    private readonly gtmService: GtmService,
    private readonly notificationService: NotificationService,
    private readonly authFacade: AuthFacade,
    private readonly authService: AuthService,
    private readonly navigationService: NavigationService,
    private readonly analyticsService: AnalyticsService,
    private userInfoService: UserInfoService,
  ) {}

    ngOnInit(): void {
        this.analyticsService.trackEvent(AnalyticsEvent.PROFILE_FORM);
    }

  close(): void {
    this.modalService.closeAll();
  }

  public setUserDetails(details: AccountDetails | null) {
    this.userDetails = details;
  }

  public onSubmit() {
    this.isLoading = true;

    this.accountService
      .updateDetails(this.userDetails)
      .then(
        status => {
          if (!status) return;

          this.accountService.refreshToken().subscribe();
          this.notificationService.showNotification({
            type: 'success',
            message: 'Successfully saved',
          });

          this.close();
          this.gtmService.pushEvent(GtmEvent.CompletedProfile);

            this.userInfoService.getUser().subscribe((user) => {
                if(!user) return;
                this.analyticsService.trackEvent(AnalyticsEvent.PROFILE_COMPLETED, {
                    player_id: user.id,
                    profile_completed: true
                });

            });

          this.modalService.open(CoinStoreComponent);
        },
        error => {
          this.authFacade.logout();
          this.authService.dispose();
          this.navigationService.navigateAndRefreshTo('');

          this.notificationService.showNotification({
            type: 'error',
            message: error.error.detail,
          });
        }
      )
      .finally(() => {
        this.isLoading = false;
      });
  }
}