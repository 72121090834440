import {Component, OnInit} from '@angular/core';
import {
    CoinPackageOffer,
    CoinPackageProvider,
    NotificationService,
} from "../../../../../core";
import {ModalService} from "../../../../services";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-purchase-coins',
    templateUrl: './purchase-coins.component.html',
    styleUrls: ['./purchase-coins.component.scss']
})
export class PurchaseCoinsComponent implements OnInit {
    public coinPackage!: CoinPackageOffer;
    public coinGridPosition: number = 0;
    public isSummary!: boolean;
    public summaryData!: any;
    public coinPackageId!: number;

    constructor(
        private readonly coinPackageProvider: CoinPackageProvider,
        private readonly notificationService: NotificationService,
        private readonly modalService: ModalService,
        private readonly dialogRef: MatDialogRef<PurchaseCoinsComponent>
    ) {
    }

    ngOnInit(): void {
        this.getCoinPackage();
    }

    public closeAll(): void {
        this.modalService.closeAll()
    }

    public close(): void {
        this.dialogRef.close();
    }

    private getCoinPackage(): void {
        this.coinPackageProvider.getCoinPackage(this.coinPackageId.toString()).subscribe({
            next: (result) => {
                this.coinPackage = result;
            },
            error: (error) => {
                this.notificationService.showNotification({
                    type: 'error',
                    message: error.error.detail
                });

                this.close();
            }
        });
    }

    openSummary(data: any) {
        this.summaryData = data;

        this.isSummary = true;
    }
}