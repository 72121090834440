export enum RedeemStatus {
    None = 0,
    Requested,
    Docs,
    Approved,
    Rejected,
    Paid,
    Failed,
    Canceled
}

export enum RedeemType {
    None = 0,
    BankDetails = 1,
    NuveiIbt = 2,
    NuveiCard = 3,
    PayNearMe = 4,
    PayNearMeCard = 5
}

export const RedeemStatusLabel = new Map<number, string>([
    [RedeemStatus.Requested, 'Pending'],
    [RedeemStatus.Docs, 'Docs'],
    [RedeemStatus.Approved, 'Approved'],
    [RedeemStatus.Rejected, 'Rejected'],
    [RedeemStatus.Paid, 'Paid'],
    [RedeemStatus.Failed, 'Failed'],
    [RedeemStatus.Canceled, 'Canceled'],
])