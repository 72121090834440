<div class="modal">
  <div class="modal__header">
      <h1>{{ data.title }}</h1>
  </div>
  <div class="modal__content">
    <p class="text">{{ data.content}}</p>
  </div>
  <div class="modal__actions">
    <div class="button-container">
      <button class="btn btn-outlined" type="button" *ngIf="data?.cancelActionLabel"
        (click)="close(false)"> {{ data.cancelActionLabel }} </button>   
        <button class="btn btn-large" type="button" *ngIf="data.confirmActionLabel"
        (click)="close(true)"> {{ data.confirmActionLabel }} </button>
    </div>
  </div>
</div>
