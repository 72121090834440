import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Directive({
    selector: '[appInViewport]'
})
export class InViewportDirective implements OnInit, OnDestroy {
    @Output() inViewport: EventEmitter<boolean> = new EventEmitter<boolean>();
    private observer!: IntersectionObserver;

    constructor(private el: ElementRef) {}

    ngOnInit(): void {
        this.observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                this.inViewport.emit(entry.isIntersecting);
            });
        }, { threshold: 0.2 });

        this.observer.observe(this.el.nativeElement);
    }

    ngOnDestroy(): void {
        this.observer.disconnect();
    }
}