import {CommonModule, CurrencyPipe, NgOptimizedImage} from '@angular/common';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {
    BannerComponent,
    CasinoHeaderComponent,
    CategoryCarouselComponent,
    CheckoutPaymentComponent,
    CommonHeaderComponent,
    FlykkPaymentComponent,
    ForumpayPaymentComponent,
    GameCardComponent,
    GameStripComponent,
    GameTabsComponent,
    GooglePaymentComponent,
    LevelDetailsComponent,
    NavigationBarComponent,
    NavigationBarMobileComponent,
    NuveiIbtPaymentComponent,
    NuveiPaymentComponent,
    PageFooterComponent,
    PaypalPaymentComponent,
    ProgressBarComponent,
    SearchDropdownComponent,
    SearchInputComponent,
    SideGamesComponent,
    SignInFormComponent,
    StaticCarouselComponent,
    ToggleCoinsComponent,
    UserDetailsFormComponent
} from './components';
import {
    ControlValidatorDirective,
    IsActivityActive,
    IsBonusActive,
    IsGeolocationActive,
    IsKycActive,
    IsNotificationActive,
    IsReportingActive,
    IsResponsibleGamblingActive,
    IsWalletActive,
    TooltipDirective,
    VarDirective,
    InViewportDirective
} from './directives';
import {
    ActivityCheckInfoComponent,
    BankDetailsConfirmationComponent,
    BankDetailsFormComponent,
    BankDetailsModalComponent,
    BankDetailsSuccessComponent,
    ClaimModalComponent,
    CoinCardComponent,
    CoinStoreComponent,
    DailySpinModalComponent,
    EditDetailsModalComponent,
    FreeCoinsModalComponent,
    LevelDetailsModalComponent,
    OldAndNewComponent,
    PaymentComponent,
    PaymentMethodComponent,
    PaymentSummaryComponent,
    PlayerLevelDetailsModalComponent,
    PopupsContainerComponent,
    PurchaseCoinsComponent,
    RedeemCardsComponent,
    RedeemCoinsComponent,
    RedeemSuccessModalComponent,
    RedeemSummaryComponent,
    SearchComponent,
    SignInModalComponent,
    TimeLimitReachedComponent, PackageButtonsComponent,
} from './modals';
import {NgxMaskModule} from 'ngx-mask';
import {
    CasinoImage,
    ClaimCoinPipe,
    DatePipe,
    DatetimePipe,
    HideCard,
    HoursPipe,
    PurchaseCoinPipe,
    TransactionCategoryPipe,
    TransactionIconPipe,
    TransactionStatusPipe,
    TransactionSourcePipe,
    SafePipe,
    NumberFormatPipe
} from './pipes';
import {RoundProgressModule} from "angular-svg-round-progressbar";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {ButtonComponent, FormControlCheckboxComponent} from "./UI";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {MatNativeDateModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {CurrencyMaskModule} from "ng2-currency-mask";
import {MaterialControlValidatorDirective} from "../core";
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from "@angular/material/radio";
import {NgxPayPalModule} from "ngx-paypal";
import {NgLetModule} from "ng-let";
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule} from "@angular/material/dialog";
import {MatGridListModule} from "@angular/material/grid-list";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import {GooglePayButtonModule} from "@google-pay/button-angular";
import {MatTooltipModule} from "@angular/material/tooltip";
import {PlayerDetailsModalComponent} from './modals/player-details-modal/player-details-modal.component';
import { PaynearmePaymentComponent } from './components/payments/paynearme-payment/paynearme-payment.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';

const COMPONENTS = [
    TimeLimitReachedComponent,
    ToggleCoinsComponent,
    GameCardComponent,
    SideGamesComponent,
    NavigationBarMobileComponent,
    SearchDropdownComponent,
    GameStripComponent,
    PageFooterComponent,
    BannerComponent,
    NavigationBarComponent,
    SearchInputComponent,
    CommonHeaderComponent,
    UserDetailsFormComponent,
    BankDetailsSuccessComponent,
    BankDetailsFormComponent,
    BankDetailsConfirmationComponent,
    SignInFormComponent,
    CasinoHeaderComponent,
    ProgressBarComponent,
    LevelDetailsComponent,
    CoinCardComponent,
    OldAndNewComponent,
    PaymentSummaryComponent,
    PaymentMethodComponent,
    PurchaseCoinsComponent,
    CoinStoreComponent,
    CheckoutPaymentComponent,
    GooglePaymentComponent,
    RedeemCardsComponent,
    RedeemSummaryComponent,
    GameTabsComponent,
    CategoryCarouselComponent,
    StaticCarouselComponent,
    PackageButtonsComponent,
    AlertDialogComponent,
]

const PAYMENTS = [
    PaypalPaymentComponent,
    FlykkPaymentComponent,
    NuveiPaymentComponent,
    ForumpayPaymentComponent,
    NuveiIbtPaymentComponent
]

const UI_COMPONENTS = [
    ButtonComponent,
    FormControlCheckboxComponent,
    OldAndNewComponent
];

const PIPES = [
    CasinoImage,
    HideCard,
    TransactionStatusPipe,
    TransactionIconPipe,
    TransactionCategoryPipe,
    TransactionSourcePipe,
    DatePipe,
    DatetimePipe,
    HoursPipe,
    PurchaseCoinPipe,
    ClaimCoinPipe,
    SafePipe,
    NumberFormatPipe,
]

const MODALS = [
    ActivityCheckInfoComponent,
    PaymentComponent,
    SearchComponent,
    ClaimModalComponent,
    DailySpinModalComponent,
    EditDetailsModalComponent,
    BankDetailsModalComponent,
    RedeemSuccessModalComponent,
    FreeCoinsModalComponent,
    SignInModalComponent,
    PopupsContainerComponent,
    RedeemCoinsComponent,
    LevelDetailsModalComponent,
    PlayerLevelDetailsModalComponent,
    PlayerDetailsModalComponent
]

const DIRECTIVES = [
    TooltipDirective,
    ControlValidatorDirective,
    MaterialControlValidatorDirective,
    VarDirective,
    IsActivityActive,
    IsBonusActive,
    IsGeolocationActive,
    IsKycActive,
    IsNotificationActive,
    IsReportingActive,
    IsResponsibleGamblingActive,
    IsWalletActive,
    InViewportDirective
]

const MATERIAL = [
    MatRadioModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatIconModule,
    MatSelectModule,
    MatDialogModule,
    MatGridListModule,
    MatSnackBarModule
]

@NgModule({
    imports: [
        ...MATERIAL,
        CommonModule,
        NgLetModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule.forRoot(),
        RoundProgressModule,
        SlickCarouselModule,
        ScrollingModule,
        CurrencyMaskModule,
        NgxPayPalModule,
        NgOptimizedImage,
        GooglePayButtonModule,
        MatTooltipModule,
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ...MATERIAL,
        ...COMPONENTS,
        ...PAYMENTS,
        ...PIPES,
        ...DIRECTIVES,
        ...MODALS,
        ...UI_COMPONENTS,
        CurrencyMaskModule
    ],
    declarations: [
        ...COMPONENTS,
        ...PAYMENTS,
        ...PIPES,
        ...DIRECTIVES,
        ...MODALS,
        ...UI_COMPONENTS,
        PaynearmePaymentComponent,

    ],
    providers: [
        CurrencyPipe,
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: {
                color: 'white'
            },
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 2500
            }
        }
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule {
}